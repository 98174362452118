<template>
  <div class="info">
    <!-- <vipPermission v-if="showVipCom" :vipPermissionVisible="true" ></vipPermission> -->
    <div class="">
      <div style="display:flex;width: 1200px;flex-wrap: wrap;">
        <div v-for="(item,index) in contact" :key="index">
          <div v-if="item.type > 1" class="info-content">
              <div>联系单位：{{item.contactUnit?item.contactUnit:'—'}}</div>
              <div>联系人：{{item.contactName?item.contactName:'—'}}</div>
              <div>联系方式：{{item.contactPhone?item.contactPhone:'—'}}</div>
              <div>备注：{{item.remark?item.remark:'—'}}</div>
          </div>
          <div v-else class="info-content">
            <div>{{item.remark}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  data () {
    return {};
  },
  mounted () {},
  computed: {
    ...mapState('policy', [ 'contact' ]),
    ...mapState({
      isVip: (state) => state.login.isVip
    }),
    showVipCom () {
      return this.isVip === '普通用户';
    }
  },
  methods: {}
};
</script>

<style lang="less" scoped>
.info {
  display: flex;
  flex-wrap: wrap;
  color: #757575;
  flex-wrap: wrap;
  width: 1200px;
  position: relative;
  // min-height: 50vh;
  .info-content {
    width: 500px;
    min-height: 300px;
    border-radius: 5px;
    border: 1px solid #CCCCCC;
    padding: 0 20px;
    box-sizing: border-box;
    &:nth-child(odd) {
      margin: 0 30px 30px 0;
    }
    &>div {
      & + div {
        border-top: 1px solid #CCCCCC;
      }
      // display: flex;
      font-size: 14px;
      padding: 12px 0;
      line-height: 22px;
      div:first-child {
        width: 100%;
        margin-bottom: 10px;
      }
      div:last-child {
        // flex: 1;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
      }
    }
  }
}
</style>
